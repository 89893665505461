body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  /* Center horizontally*/
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  margin-bottom : 30px
}

.filetype {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-parent-element {
  display: flex;
  width: 50%;
  justify-content: center;
}

.flex-child-element {
  flex: 1 1 auto;
  /* border: 2px solid blueviolet;  */
  margin: 10px;
  text-align: center;
  margin-top: 20px;
  
}

.flex-child-element:first-child {
  margin-right: 20px;
}


.form-container {
  display: flex;
  justify-content: center;
  margin-bottom : 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.action-button-container {
  display: flex;
  justify-content: center;
  margin-bottom : 30px;
  margin-left: 40px;
  margin-right: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

nav {
  background-color: #1aa2dc;
}


.dropzone-button{
  margin-bottom: 10px;
}


#okta-sign-in .auth-org-logo {
  height: 30px;
}

#okta-sign-in.auth-container .button-primary {
  background: #6cb86a;
  border-color: #6db86ac4;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: background-color, box-shadow, border-color, color;
  transition-duration: 250ms, 250ms, 250ms, 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1),
    cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms, 0ms, 0ms, 0ms;
}

#okta-sign-in.auth-container .button-primary:hover,
#okta-sign-in.auth-container .button-primary:active {
  background: rgb(75, 128, 74);
  border-color: transparent;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

#okta-sign-in.auth-container input[type='submit']:focus {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-color: transparent;
  background: rgb(75, 128, 74);
}

#okta-sign-in.auth-container .button.button-primary.link-button-disabled {
  background-color: #70c96d;
  border-color: #70c96d;
}

#okta-sign-in .focused-input,
#okta-sign-in .link.help:focus {
  box-shadow: 0 0 8px #6cb86a;
}

#okta-sign-in.auth-container .okta-form-input-field.focused-input {
  border-color: transparent;
}

#okta-sign-in .o-form-label-top .o-form-input {
  border-color: transparent;
  box-shadow: transparent;
}

#okta-sign-in .custom-checkbox {
  box-shadow: none;
}
